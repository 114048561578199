/*
This module is evil and only exists for backwards compatibility. Try to
avoid using it and find ways to split z into smaller functional modules.
*/

// Make z wide-open re flow types. We wanna get rid of it anyways.
const z: Record<string, any> = {};

// static config variables
z.cfg = {
  api: '/api/v3',
};

// uninstantiated models
z.model = {};

// uninstantiated views
z.ui = {};

// instantiated instances
z.app = {};

// Leaving this here for debugging now, but going to have to wean ourselves off
// of this.
window.z = z;

export default z;
