import { capitalize } from 'lodash';

import { addParamsToUrl } from '@zapier/url-utils';
import { singular } from '@zapier/common-utils';
import { ZAPIER_APP_BASE_URL } from 'app/common/constants';

import { ZapMeta, typeMap, OptionsType } from 'app/developer-v3/types/zapLink';

const defaultTriggerParams = {
  'steps[0][app]': 'ScheduleCLIAPI@latest',
  'steps[0][type]': 'read',
  'steps[0][action]': 'everyHour',
};

function createZapTitle(zap) {
  const { actionLabel, platformSchemaType, service } = zap;
  const format = type => singular(capitalize(type));

  return `Test Zap for ${actionLabel} ${format(platformSchemaType)} from ${
    service.name
  } Version ${service.version}`;
}

/**
 * Creates a link that will auto-create a Zap in the Zap Editor.
 * If it's an action, we will add a trigger from Schedule by Zapier.
 */
function buildZapLink(zap: ZapMeta, options?: OptionsType) {
  const { referrer } = options || {};
  const type = typeMap[zap.platformSchemaType];
  // a platform trigger is a `node.type_of: 'read'` in the editor.
  const isTrigger = type === 'read';
  const stepIndex = isTrigger ? 0 : 1;
  const templateKey = `steps[${stepIndex}]`;

  const queryParams = {
    referrer,
    'steps[0][title]': createZapTitle(zap),
    ...(isTrigger ? {} : defaultTriggerParams),
    [`${templateKey}[app]`]: zap.selectedApi,
    [`${templateKey}[type]`]: type,
    [`${templateKey}[action]`]: zap.action,
  };

  return addParamsToUrl(
    `${ZAPIER_APP_BASE_URL}/webintent/create-zap`,
    queryParams
  );
}

export { buildZapLink };
