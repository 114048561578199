import React, { createContext, useContext } from 'react';

type TrackException = (error: any, data: any) => void;

interface ProviderProps {
  trackException(error: any, data: any): void;
  children: React.ReactNode;
}

function defaultTrackException(error: any, data: any) {
  console.error('Tracking exception', { error, data });
}

export const TrackExceptionContext = createContext<TrackException>(
  defaultTrackException
);

export function ExceptionTrackingProvider({
  children,
  trackException,
}: ProviderProps) {
  return (
    <TrackExceptionContext.Provider value={trackException}>
      {children}
    </TrackExceptionContext.Provider>
  );
}

export default function useTrackException() {
  return useContext(TrackExceptionContext);
}
